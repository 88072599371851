@import "@/src/styles/common.scss";
.ul-productlist-container {
    &.singleCol {
        & > ul {
            & > li > div {
                padding: 0.18rem 0.3rem 0.54rem;
                width: 100%;
            }
        }
    }
    &.doubleCol {
        & > ul {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            padding: 0.08rem 0.2rem;
            justify-content: space-between;
            & > .list-item-container:not(:last-of-type) {
                margin: 0;
                width: calc(50% - 0.15rem);
                padding: 0.1rem 0.2rem 0.32rem;
                box-shadow: 0px 6px 12px 2px rgba(0, 0, 0, 0.05);
            }
            & > .list-item-container:last-of-type {
                width: 100%;
                justify-content: center;
            }
            & > .list-item-container:nth-of-type(n + 3) {
                margin-top: 0.34rem;
            }
            .button-props-classes {
                margin-top: 0.34rem;
            }
        }
    }
    // 新版B模版样式
    &.doubleCol-B {
        & > ul {
            width: 100%;
            column-count: 2;
            padding: 0.08rem 0.2rem;
            & > .list-item-container:not(:last-of-type) {
                break-inside: avoid;
                padding: 0.1rem 0.2rem 0.32rem;
                box-shadow: 0px 6px 12px 2px rgba(0, 0, 0, 0.05);
            }
            & > .list-item-container:last-of-type {
                width: 100%;
                justify-content: center;
            }
            & > .list-item-container {
                margin-bottom: 0.15rem;
            }
        }
    }
    .single-padding {
        padding: 0.08rem 0.15rem;
    }
}
.list-item {
    padding: 0;
    margin: 0;
}
.ads {
    width: 100% !important;
    padding: 0 !important;
}
