@import "@/src/styles/common.scss";
// 产品项颜色选择模块
.color-select {
    display: flex;
    margin-bottom: 0.18rem;
    .color-select-skeleton {
        width: 0.45rem;
        height: 0.45rem;
        margin: 0.03rem;
    }
}
