@import "@/src/styles/common.scss";
// 可重复使用的样式

@mixin double-product() {
    margin: 0 0.1rem 0.18rem;
    padding: 0.18rem;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}
.count_wrap {
    color: #ff264f;

    padding-right: 5px;
}

.product-tryon-line {
    width: 100%;
}

.product-tryon-line p {
    font-size: 0.42rem;
    color: $text;
}

.product-tryon-line .p_w {
    text-align: right;
}

.product-off {
    min-width: 58px;
    height: 0.28rem;
    background: url("/__firmoo/static/icon/phone/bg_off.png") no-repeat center
        center;
    background-size: 100%;
    font-size: 0.24rem;

    color: rgba(255, 255, 255, 1);
    padding-left: 0.09rem;
    padding-right: 0.1rem;
    line-height: 0.28rem;
}

.product-info-line {
    width: 100%;
    margin-top: 0.36rem;
    line-height: 1;
    position: relative;
    .product-row-heart {
        font-size: 0;
        .product-icon-heart {
            font-size: 0.44rem;
            .icon-heart {
                line-height: unset;
            }
        }
    }
}
.price-tryon-button-container {
    .p_w {
        margin-top: 0.06rem;
        @include use-font-normal();
        font-family: var(--font-montserrat-bold);
    }
}

.product-info-line .product-icon-heart {
    width: 0.32rem;
    height: auto;
}

.product-info-line .product-state-icon {
    width: 0.55rem;
    height: auto;
    margin-right: 0.1rem;
}

.product-info-line .product-name {
    margin: 0;
    @include use-font-small();
}

.product-info-line .product-reviews-p {
    font-family: var(--font-montserrat);
    font-size: 0.24rem;
    color: $text;
    line-height: 0.18rem;
    margin-left: 0.1rem;
}

.product-item-img-container {
    display: block;
    position: relative;
}

.product-row-space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon-heart {
        font-size: 0.44rem;
    }
}

.price-tryon-button-container {
    font-size: 0.24rem;
    .p_w {
        margin-top: 0.06rem;
        color: $main;
        .price-span {
            @include use-font-normal();
            font-family: var(--font-montserrat-medium);
        }
        s {
            @include use-font-normal();
            color: #92969c;
            font-family: var(--font-montserrat-bold);
        }
    }
}
.show-single-style {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.18rem;
    .p_w {
        order: 1;
    }
}

.try-on-button {
    margin-top: 0;
    &.not-single-button {
        margin-top: 0.18rem;
    }
}
.product-row {
    display: flex;
    align-items: center;
}
.img-object-fit {
    object-fit: contain;
}

.yblank {
    display: inline-block;
    height: 0.36rem;
    width: 0.02rem;
}

.flex-row-reverse {
    flex-direction: row-reverse;
}

.price-and-tryon {
    width: 100%;
}

.product-item-container {
    border-bottom: none;
    padding: 0;
    width: 100%;
    .color-select-container {
        justify-content: flex-start;
        margin-top: 10px;
    }
    &.double-product-b-template {
        // 双列下的B模板
        height: 100%;
        display: flex;
        flex-direction: column;
        width: 100%;
        .product-flex-discriptions-when-templateb-double {
            flex: 1;
        }
    }
    .color-select-cotainer-of-a-template {
        margin-top: 0.2rem;
        justify-content: center;
    }
}

/* 更改专题页颜色选择器的样式 */
.color-select .product-select {
    margin-bottom: 0 !important;
}

.color-select .product-select .color-more {
    width: 0.45rem;
    height: 0.45rem;
    padding: 0 0 0 0;
    margin: 0 0.07rem 0 0.07rem;
}

// .color-select .product-select .product-select-item-content {
//     display: inline-flex;
//     align-items: center;
//     justify-content: center;
//     width: 0.453rem;
//     height: 0.453rem;
//     padding: 0 0 0 0;
//     margin: 0 0.072rem 0 0.072rem;
// }

.color-select .product-select .product-select-item {
    width: 0.3rem;
    height: 0.3rem;
}

.single-product {
    width: 6.9rem;
    border-bottom: none;
    margin: 0 auto;
    padding: 0.14rem 0.16rem 0.8rem;
}

// .double-product {
//     @include double-product();
// }

.b-template-skeleton-container {
    .description-of-products {
        margin: 10px 0;
        width: 100%;
        .description-skeleton {
            font-size: 0.24rem;
        }
    }
    .product-row-space-between {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name-skeleton,
        .price-tryon-button-container {
            width: calc(50% - 0.15rem);
        }
    }
    .try-on-icon-full-start-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon-full-start {
            width: calc(50% - 0.15rem);
        }
        .tryon-button {
            width: 30%;
        }
    }
    &:not(.double-product) {
        .name-skeleton,
        .price-tryon-button-container {
            width: 20%;
        }
        .icon-full-start {
            width: 10%;
        }
        .tryon-button {
            width: 20%;
        }
    }
}

.a-template-skeleton-container,
.b-template-skeleton-container {
    // single
    padding: 0.18rem 0.15rem 0.54rem;
    width: 6.9rem;
    margin: 0 auto;
    .image-skeleton {
        min-height: 3.35rem;
        width: 100%;
    }
    // double
    &.double-product {
        width: calc(50% - 0.3rem);
        @include double-product();
        .image-skeleton {
            height: 80px;
            min-height: 1.54rem;
        }
    }
    .show-empty-tag {
        background-color: transparent;
        margin-bottom: 0.18rem;
    }
}

.a-template-skeleton-container {
    .product-name-empty-heart-container {
        margin-top: 0.36rem;
        display: flex;
        justify-content: space-between;
        .product-name-skeleton {
            font-size: 0.28rem;
            width: 30%;
        }
    }
    .product-price-tryon-container {
        .product-price-skeleton {
            width: 20%;
        }
    }
    &:not(.double-product) {
        .product-price-tryon-container {
            display: flex;
            justify-content: space-between;
            .product-price-skeleton {
                order: 1;
            }
            .product-try-on-skeleton {
                width: 20%;
            }
        }
    }

    &.double-product {
        .product-name-empty-heart-container {
            margin-top: 0.18rem;
        }
        .product-price-tryon-container {
            .product-price-skeleton {
                font-size: 0.32rem;
            }
            .product-try-on-skeleton {
                width: 100%;
                margin-top: 0.18rem;
                height: 0.48rem;
            }
        }
    }

    .image-skeleton {
        margin-bottom: 10px;
    }
    .color-container-of-a-template {
        justify-content: center;
    }
}

.tags-container {
    display: flex;
}
